import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import './static/css/ImageCarousel.css';

const ImageCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handlers = useSwipeable({
        onSwipedLeft: nextImage,
        onSwipedRight: prevImage
    });

    return (
        <div className="carousel-container" {...handlers}>
            <img src={images[currentIndex]} alt="Apartment" className="carousel-image" />
            <button className="carousel-button carousel-button-prev" onClick={prevImage}>&lt;</button>
            <button className="carousel-button carousel-button-next" onClick={nextImage}>&gt;</button>
        </div>
    );
}

export default ImageCarousel;
