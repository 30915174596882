import React from "react";
import Menu from './Menu';
import "./static/css/Guides.css";

const Guides = () => {
  const placesToVisit = [
    {
      name: "Piazza del Plebiscito",
      description:
        "Naples’ main city square, surrounded by historic buildings.",
      link: "https://en.wikipedia.org/wiki/Piazza_del_Plebiscito",
      imageUrl: "https://via.placeholder.com/150?text=Piazza+del+Plebiscito",
    },
    {
      name: "Castel dell’Ovo",
      description: "The oldest standing fortification in Naples.",
      link: "https://en.wikipedia.org/wiki/Castel_dell%27Ovo",
      imageUrl: "URL_Castel_dell’Ovo",
    },
    {
      name: "Naples National Archaeological Museum",
      description:
        "One of the world’s finest collections of Greco-Roman artifacts.",
      link: "https://en.wikipedia.org/wiki/National_Archaeological_Museum,_Naples",
      imageUrl: "URL_Archaeological_Museum",
    },
    {
      name: "Spaccanapoli",
      description:
        "The historic street cutting through the heart of the ancient city.",
      link: "https://en.wikipedia.org/wiki/Spaccanapoli",
      imageUrl: "URL_Spaccanapoli",
    },
    {
      name: "Naples Underground Geothermal Zone",
      description: "Explore the fascinating underground of Naples.",
      link: "https://en.wikipedia.org/wiki/Naples_underground_geothermal_zone",
      imageUrl: "URL_Underground",
    },
    {
      name: "Naples Cathedral",
      description:
        "A beautiful 14th-century cathedral dedicated to the Assumption of the Virgin Mary.",
      link: "https://en.wikipedia.org/wiki/Naples_Cathedral",
      imageUrl: "URL_Cathedral",
    },
    {
      name: "San Gregorio Armeno",
      description: "Known for its vibrant Christmas market and artisan shops.",
      link: "https://en.wikipedia.org/wiki/San_Gregorio_Armeno",
      imageUrl: "URL_San_Gregorio",
    },
    {
      name: "Mount Vesuvius",
      description: "The infamous volcano overlooking the Bay of Naples.",
      link: "https://en.wikipedia.org/wiki/Mount_Vesuvius",
      imageUrl: "URL_Vesuvius",
    },
    {
      name: "Capodimonte Museum",
      description:
        "Home to numerous artworks from the Renaissance and Baroque periods.",
      link: "https://en.wikipedia.org/wiki/Capodimonte_Museum",
      imageUrl: "URL_Capodimonte",
    },
    {
      name: "Castel Nuovo",
      description: "A medieval castle located in front of Piazza Municipio.",
      link: "https://en.wikipedia.org/wiki/Castel_Nuovo",
      imageUrl: "URL_Castel_Nuovo",
    },
    {
      name: "Via San Gregorio Armeno",
      description: "Famous street known for its nativity workshops.",
      link: "https://en.wikipedia.org/wiki/Via_San_Gregorio_Armeno",
      imageUrl: "URL_Via_San_Gregorio",
    },
    {
      name: "Royal Palace of Naples",
      description:
        "A historic palace offering a glimpse into the life of Spanish and Bourbon royalty.",
      link: "https://en.wikipedia.org/wiki/Royal_Palace_of_Naples",
      imageUrl: "URL_Royal_Palace",
    },
    {
      name: "The Catacombs of San Gennaro",
      description:
        "Ancient underground burial sites situated in the northern part of Naples.",
      link: "https://en.wikipedia.org/wiki/Catacombs_of_San_Gennaro",
      imageUrl: "URL_Catacombs",
    },
    {
      name: "San Domenico Maggiore",
      description:
        "A historic church and square offering insight into Naples’ religious history.",
      link: "https://en.wikipedia.org/wiki/San_Domenico_Maggiore",
      imageUrl: "URL_San_Domenico",
    },
    {
      name: "Ovo Castle",
      description:
        "A seaside fortress offering panoramic views of the Gulf of Naples.",
      link: "https://en.wikipedia.org/wiki/Ovo_Castle",
      imageUrl: "URL_Ovo_Castle",
    },
  ];

  return (
    <div className="Guides">
      <Menu />
      <header className="Guides-header">
        <div className="content-wrapper">
          <h1>
            Top 15 Places to Visit in Naples
          </h1>
          <p>
            Discover the beauty and history of Naples through these must-visit
            attractions.
          </p>

          <ul className="places-list">
            {placesToVisit.map((place, index) => (
              <li key={index} className="place-item">
                <div className="place-box">
                  <a
                    href={place.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="App-link"
                  >
                    <h2>{place.name}</h2>
                  </a>
                  <p>{place.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </header>
    </div>
  );
};

export default Guides;
