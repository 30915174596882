import React from 'react';
import './static/css/Apartments.css';
import Menu from './Menu';
import ImageCarousel from './ImageCarousel';
import apartment1Image1 from './static/images/img1.jpg';
import apartment1Image2 from './static/images/img2.jpg';
import apartment1Image3 from './static/images/img3.jpg';
import apartment1Image4 from './static/images/img4.jpg';

const Apartments = () => {
    return (
        <div className="apartments-container">
            <Menu />
            <h1>Our Apartments</h1>
            <div className="apartment-list">
                {/* Example of displaying list of apartments */}
                <div className="apartment-item">
                    <h2>Alla Scoperta Del Vomero</h2>
                    <ImageCarousel images={[apartment1Image1, apartment1Image2]} />
                    <p>Perfect solution for a family in a residential area</p>
                    <a href="https://airbnb.it/h/allascopertadelvomero" className="book-button">Book here</a>
                </div>
                <div className="apartment-item">
                    <h2>All'Ombra del Vesuvio Rooms Duomo</h2>
                    <ImageCarousel images={[apartment1Image3, apartment1Image4]} />
                    <p>Perfect solution for a couple visiting the historic center of Naples</p>
                    <a href="https://airbnb.com/h/allombradelvesuviorooms" className="book-button">Book here</a>
                </div>   
            </div>
        </div>
    );
}

export default Apartments;
