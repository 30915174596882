import React from 'react';
import './static/css/HomeCarousel.css';
import ImageCarousel from './ImageCarousel'; // Assuming it's in the same directory

const HomeCarousel = () => {
    const items = [
        { 
            id: 1, 
            title: "Visit Pompeii", 
            description: "Experience the ancient city preserved by volcanic ash.",
            images: ['path/to/image1.jpg', 'path/to/image2.jpg'] 
        },
        { 
            id: 2, 
            title: "Climb Mount Vesuvius", 
            description: "Hike up the famous volcano and take in the views.",
            images: ['path/to/image3.jpg', 'path/to/image4.jpg'] 
        },
        { 
            id: 3, 
            title: "Explore Naples Underground", 
            description: "Discover Naples’ subterranean wonders.",
            images: ['./static/images/img4.jpg', 'path/to/image6.jpg'] 
        },
        // Add more items as needed
    ];

    return (
        <div className="home-carousel-container">
            {items.map(item => (
                <div key={item.id} className="home-carousel-card">
                    <h3>{item.title}</h3>
                    <ImageCarousel images={item.images} />
                    <p>{item.description}</p>
                </div>
            ))}
        </div>
    );
}

export default HomeCarousel;